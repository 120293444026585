import React, {useMemo} from 'react';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import withStore from "@/hocs/withStore";
import CountryList from "@/Pages/Header/CountryList";

const Country = ({stores = {}, config = {}}) => {
    const {countryStore = {}} = stores;
    const {countryList} = config;
    const countryListShift = useMemo(() => Object.assign({}, countryList[0]), [countryList]);

    return <div className={`headerBox__country`}>
        <ClickAwayListener onClickAway={countryStore.handleClickAway}>
            <div className={`country`}>
                <div className={`country__currentBox`} onClick={() => countryStore.switchingCountries()}>
                    <p className={`country__currentName`}>{countryListShift.value}</p>
                    <picture className={`country__currentFlag`}>
                        <source type={`image/webp`} srcSet={countryListShift.patchWebp} />
                        <img
                            src={countryListShift.patch}
                            alt={`Ru`}
                            title={`Ru`}
                            loading={`lazy`}
                            width={`14`}
                            height={`14`}
                        />
                    </picture>
                </div>
                <Fade in={countryStore.countryChecked}>
                    <Paper
                        elevation={4}
                        style={{
                            position: 'absolute',
                            top: '24px',
                            width: '300px',
                            backgroundColor: '#FFFFFF',
                            padding: '16px',
                            borderRadius: '8px',
                            zIndex: 2,
                        }}
                    >
                        <p className={`country__title`}>Выберите страну</p>
                        <CountryList countryList={countryList} />
                    </Paper>
                </Fade>
            </div>
        </ClickAwayListener>
    </div>
}

export default withStore(Country);
